import { LayoutAuth, LayoutDefault, RouteWrapper } from '@/components/layouts'

export const publicRoute = [
  {
    path: '/auth',
    component: LayoutAuth,
    meta: { title: 'auth_login' },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: { title: 'auth_login' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
      },
      {
        path: 'logout',
        name: 'Logout',
        meta: { title: 'auth_logout' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Logout.vue'),
      },
    ],
  },

  {
    path: '/password',
    component: LayoutAuth,
    meta: { title: 'password' },
    redirect: '/password/reminder',
    hidden: true,
    children: [
      {
        path: 'reminder',
        name: 'PasswordReminder',
        meta: { title: 'password_reminder' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/password/Reminder.vue'),
      },
    ],
  },

  {
    path: '/403',
    name: 'Forbidden',
    meta: { title: 'error_403' },
    component: () => import(/* webpackChunkName: "errors-404" */ '@/views/error/Deny.vue'),
  },

  {
    path: '/404',
    name: '404',
    meta: { title: 'error_404' },
    component: () => import(/* webpackChunkName: "errors-404" */ '@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: { title: 'error_500' },
    component: () => import(/* webpackChunkName: "errors-500" */ '@/views/error/Error.vue'),
  },
]

export const protectedRoute = [
  {
    path: '/',
    component: LayoutDefault,
    meta: { title: 'top', icon: '' },
    children: [
      {
        path: '',
        name: 'TopIndex',
        meta: {
          title: 'top_index',
          icon: 'home',
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/top/Index.vue'),
      },

      {
        path: '/screener',
        component: RouteWrapper,
        meta: { title: 'screener', icon: 'search' },
        redirect: '/screener/index',
        children: [
          {
            path: '/screener/index',
            name: 'ScreenerIndex',
            meta: {
              title: 'screener_index',
              icon: 'search',
              breadcrumb: false,
            },
            component: () => import('@/views/screener/Index.vue'),
          },
          {
            path: '/screener/:screener_id',
            name: 'ScreenerDetail',
            meta: {
              title: 'screener_detail',
              icon: 'pageview',
              breadcrumb: false,
            },
            component: () => import('@/views/screener/Detail.vue'),
          },
        ],
      },

      {
        path: '/qonqranking',
        component: RouteWrapper,
        meta: { title: 'qonq', icon: 'fab fa-wpforms' },
        redirect: '/qonqranking/index',
        children: [
          {
            path: '/qonqranking/index',
            name: 'QonqrankingIndex',
            meta: {
              title: 'qonqranking_index',
              icon: 'emoji_events',
              breadcrumb: false,
            },
            component: () => import('@/views/qonqranking/Index.vue'),
          },
        ],
      },

      {
        path: '/company',
        component: RouteWrapper,
        meta: { title: 'company', icon: 'domain' },
        redirect: '/company/index',
        children: [
          {
            path: '/company/index',
            name: 'CompanyIndex',
            meta: {
              title: 'company_index',
              icon: 'domain',
              breadcrumb: false,
            },
            component: () => import('@/views/company/Index.vue'),
          },
        ],
      },

      {
        path: '/qonq',
        component: RouteWrapper,
        meta: { title: 'qonq', icon: 'fab fa-wpforms' },
        redirect: '/qonq/index',
        children: [
          {
            path: '/qonq/index',
            name: 'QonqIndex',
            meta: {
              title: 'qonq_index',
              icon: 'fab fa-wpforms',
              breadcrumb: false,
            },
            component: () => import('@/views/qonq/Index.vue'),
          },
          {
            path: '/qonq/:code',
            name: 'QonqDetail',
            meta: {
              title: 'qonq_detail',
              icon: 'pageview',
              breadcrumb: false,
            },
            component: () => import('@/views/qonq/Index.vue'),
          },
        ],
      },

      {
        path: '/alert',
        component: RouteWrapper,
        meta: { title: 'alert', icon: 'notifications' },
        redirect: '/alert/index',
        children: [
          {
            path: '/alert/index',
            name: 'AlertIndex',
            meta: {
              title: 'alert_index',
              icon: 'notifications',
              breadcrumb: false,
            },
            component: () => import('@/views/alert/Index.vue'),
          },
        ],
      },

      {
        path: '/futures',
        component: RouteWrapper,
        meta: { title: 'futures', icon: 'fas fa-chart-line' },
        redirect: '/futures/index',
        children: [
          {
            path: '/futures/index',
            name: 'FuturesIndex',
            meta: {
              title: 'futures_index',
              icon: 'fas fa-chart-line',
              breadcrumb: false,
            },
            component: () => import('@/views/futures/Index.vue'),
          },
        ],
      },

      {
        path: '/option',
        component: RouteWrapper,
        meta: { title: 'option', icon: 'fas fa-chart-line' },
        redirect: '/option/index',
        children: [
          {
            path: '/option/index',
            name: 'OptionIndex',
            meta: {
              title: 'option_index',
              icon: 'fas fa-chart-line',
              breadcrumb: false,
            },
            component: () => import('@/views/option/Index.vue'),
          },
        ],
      },

      {
        path: '/user',
        component: RouteWrapper,
        meta: { title: 'user', icon: 'person' },
        redirect: '/user/index',
        children: [
          {
            path: '/user/index',
            name: 'UserIndex',
            meta: {
              title: 'user_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/user/Index.vue'),
          },
          {
            path: '/user/create',
            name: 'UserCreate',
            meta: { title: 'user_create', icon: 'add' },
            component: () => import('@/views/user/Create.vue'),
          },
          {
            path: '/user/detail/:user_id',
            name: 'UserDetail',
            meta: { title: 'user_detail', icon: 'pageview', hidden: true },
            component: () => import('@/views/user/Detail.vue'),
          },
          {
            path: '/user/otp/:user_id',
            name: 'UserOtp',
            meta: {
              title: 'user_otp',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/user/Otp.vue'),
          },
        ],
      },

      {
        path: '/companyadmin',
        component: RouteWrapper,
        meta: { title: 'companyadmin', icon: 'admin_panel_settings' },
        redirect: '/companyadmin/index',
        children: [
          {
            path: '/companyadmin/index',
            name: 'CompanyadminIndex',
            meta: {
              title: 'companyadmin_index',
              icon: 'admin_panel_settings',
              breadcrumb: false,
            },
            component: () => import('@/views/companyadmin/Index.vue'),
          },
        ],
      },

      {
        path: '/screeneradmin',
        component: RouteWrapper,
        meta: { title: 'screeneradmin', icon: 'manage_search' },
        redirect: '/screeneradmin/index',
        children: [
          {
            path: '/screeneradmin/index',
            name: 'ScreeneradminIndex',
            meta: {
              title: 'screeneradmin_index',
              icon: 'list_alt',
              breadcrumb: false,
            },
            component: () => import('@/views/screeneradmin/Index.vue'),
          },
          {
            path: '/screeneradmin/create',
            name: 'ScreeneradminCreate',
            meta: { title: 'screeneradmin_create', icon: 'add' },
            component: () => import('@/views/screeneradmin/Create.vue'),
          },
          {
            path: '/screeneradmin/:screener_id',
            name: 'ScreeneradminDetail',
            meta: {
              title: 'screeneradmin_detail',
              icon: 'pageview',
            },
            component: () => import('@/views/screeneradmin/Detail.vue'),
          },
        ],
      },
    ],
  },
]
